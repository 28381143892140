import React, {useContext, useEffect, useState} from 'react';
import {Helmet} from 'rnd-helmet';
import {injectIntl} from "react-intl";
import Form from 'form';
import dispatchRerender from 'dispatchRerender';
import FormContext from "formContext";
import axios from 'axios';
import SubMenu from "@Components/SubMenu";
import GetRouteByName from 'getRouteByName';

function RtbfLoggedOut({intl, staticContext, location, match}) {
  const {setAdditionalData, formData, setFormData, handleErrors, renderStep, currentStep, setErrors, additionalData} = useContext(FormContext);
  const confirmationPage = GetRouteByName("request-confirmation").url

  useEffect(() => {
    if (window.__ROUTE_DATA__) {
      delete window.__ROUTE_DATA__;
    }
    else {
      axios.get(`${process.env.REACT_APP_API_PREFIX}/user-data?currentLanguage=${locale}`)
        .then(response => {
          if (response.data.redirect === true && response.data.location) {
            window.location.href = response.data.location;
          }
          else {
            setAdditionalData(response.data);
          }
        });
    }

    dispatchRerender();
    setFormData([]);
  }, []);

  const handleChange = event => {
    let name = event.target.name;
    let value = event.target.value;
    if (event.target.type === 'checkbox') {
      let checkboxValue = event.target.value;
      if (typeof checkboxValue === 'string' && checkboxValue === 'true') {
        checkboxValue = true;
      }
      value = event.target.checked ? checkboxValue : null;
    }

    setFormData((prev) => {
      if (!prev[currentStep]) {
        prev[currentStep] = {};
      }
      prev[currentStep][name] = value;
      return {
        ...prev
      }
    });
  };

  const handleSubmit = event => {
    event.preventDefault();
    setErrors();

    if (currentStep === 0) {
      axios.post(`${process.env.REACT_APP_API_PREFIX}/rtbf-send-verification`, formData[currentStep])
        .then(response => {
          renderStep('next');
        })
        .catch(error => {
          handleErrors(error.response.data);
        });
    }
    else if (currentStep === 1) {
      axios.post(`${process.env.REACT_APP_API_PREFIX}/rtbf-code-verification`, formData[currentStep])
        .then(response => {
          renderStep('next');
        })
        .catch(error => {
          handleErrors(error.response.data);
        });
    }
    else if (currentStep === 2) {
      axios.post(`${process.env.REACT_APP_API_PREFIX}/rtbf-deletion-submit`, formData[currentStep])
        .then(response => {
          window.location = confirmationPage;
        })
        .catch(error => {
          handleErrors(error.response.data);
        });
    }
  };

  const submitRegistered = event => {
    event.preventDefault();
    setErrors();

    if (currentStep === 0) {
      axios.post(`${process.env.REACT_APP_API_PREFIX}/rtbf-authenticated-submit`, {validateInput: formData[currentStep] || {}, user: additionalData.user})
        .then(response => {
          window.location = confirmationPage;
        })
        .catch(error => {
          handleErrors(error.response.data);
        });
    }
  }

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({id: 'Menu.RTBF'})} | {intl.formatMessage({id: 'Breadcrumb.Dashboard'})}</title>
        <header className="header header--text bg-variant-brand-primary"/>
      </Helmet>
      <div className="header header--text bg-variant-brand-primary">
        <div className="header__wrapper wrapper">
          <SubMenu />
          <div className="header__content header__content--l content-block header__content--full-width has-breadcrumbs">
            <h1 className="content-block__title">
              <span className="content-block__title-top">
                {
                  intl.formatMessage({id: "RTBF.Logged.Out.Header.Title"})
                }
              </span>
            </h1>
          </div>
        </div>
      </div>
      <div className="right-to-be-forgotten">
      {
        additionalData.user ?
          <Form name='rtbf_registered' state={formData[currentStep]} handleChange={handleChange} handleSubmit={submitRegistered}/>
          : <Form name='rtbf_logged_out' handleChange={handleChange} state={formData[currentStep]} handleSubmit={handleSubmit}/>

      }
      </div>
    </>
  )
}

export default injectIntl(RtbfLoggedOut);
